import { render, staticRenderFns } from "./Navbar2.vue?vue&type=template&id=34dccc5f"
import script from "./Navbar2.vue?vue&type=script&lang=js"
export * from "./Navbar2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtImg: require('/codebuild/output/src704632965/src/client/components/NuxtImg.vue').default,GlobalSearch: require('/codebuild/output/src704632965/src/client/components/typeahead/GlobalSearch.vue').default,BDropdownHover: require('/codebuild/output/src704632965/src/client/components/BDropdownHover.vue').default,CategoriesMenuItems: require('/codebuild/output/src704632965/src/client/components/navbar/CategoriesMenuItems.vue').default,NavLink: require('/codebuild/output/src704632965/src/client/components/navbar/NavLink.vue').default,BoxItems: require('/codebuild/output/src704632965/src/client/components/boxItems/index.vue').default,MPowerValidateInput: require('/codebuild/output/src704632965/src/client/components/MPowerValidateInput.vue').default,CustomerCapture: require('/codebuild/output/src704632965/src/client/components/CustomerCapture.vue').default,CartItems: require('/codebuild/output/src704632965/src/client/components/CartItems.vue').default,Loader: require('/codebuild/output/src704632965/src/client/components/Loader.vue').default})
