
  import { mapGetters } from 'vuex';
  import { calcOrderTotal, calcPOPartTotal } from '../plugins/cartItemsMixin';
  export default {
    components: {
      InventoryStatus: () => import('./productView/InventoryStatus'),
      CartItemsViewPartPrice: () => import('./CartItemsViewPartPrice'),
    },

    props: {
      order: {
        type: Object,
        required: true,
      },
      hideTotals: Boolean,
    },
    methods: {
      calcOrderTotal,
      calcPOPartTotal,
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
        storeCurrencySign: 'storeCurrencySign',
      }),
      typesetInfo() {
        return this.order.typeset?.join(', ');
      },
      productDiscounts() {
        return [this.order, ...(this.order.childPos || [])]
          .map((el) => parseFloat(el.productDiscount))
          .reduce((cur, next) => parseFloat(cur) + parseFloat(next), 0);
      },
    },
  };
