
  import { mapGetters } from 'vuex';
  import generator from 'generate-password';
  import auth from '@/plugins/authMixins';
  import constantsMixin from '@/plugins/constantsMixin';
  import dataLayerMixin from '@/plugins/dataLayer.mixin.js';
  const Cookie = process.client ? require('js-cookie') : undefined;
  export default {
    inject: ['$validator'],
    mixins: [auth, constantsMixin, dataLayerMixin],
    components: {
      MPowerValidateInput: () => import('../MPowerValidateInput'),
      MPowerMultiselect: () => import('../MPowerMultiSelect'),
      PhoneFields: () => import('@mtntop/utils/src/components/PhoneFields'),
    },
    data() {
      return {
        storeRoles: [],
        passwordType: 'password',
        customer: {
          CustomerPhoneNumbers: [
            { countryCode: '1', countryISO: this.$store.getters.DEFAULT_COUNTRY_CODE, phoneType: 'Cell', phoneNumber: null, extension: null },
          ],
          isActive: true,
        },
        type: '',
        loaded: false,
        loading: false,
        countries: [],
        customerCompany: '',
      };
    },
    props: {
      customerId: String,
      emitSubmit: Boolean,
      loadOrders: Boolean,
      loadRoles: Boolean,
      container: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapGetters({
        isAdmin: 'auth/isAdmin',
        storeId: 'storeId',
        storeInformation: 'storeInformation',
      }),
    },
    async mounted() {
      if (this.storeInformation.requireSSO) {
        this.$router.push('/signin');
      }

      if (this.container.hideCreatePassword) {
        this.$set(this.customer, 'sendRegistrationEmail', true);
      }

      try {
        const customerId = this.$route.params.customerId || this.customerId;
        if (customerId) {
          this.customer = (await this.$axios.get(`/customers/${customerId}`)).data;
          if (this.customer) {
            if (this.customer.userId) this.customer.userId = this.customer.userId._id;
            this.customerCompany = this.customer.companyId ? this.customer.companyId.companyName : '';
            this.type = 'Edit';
          }
        } else {
          this.type = 'Create';
        }
        if (this.loadRoles) {
          this.storeRoles = (
            await this.$api.stores.getStoreRoles({
              or: [{ storeId: [{ val: this.storeInformation._id }] }, { isGlobal: [{ val: true }] }],
              limit: 'all',
            })
          ).data;
        }
        this.loaded = true;
      } catch (e) {
      } finally {
      }
    },
    methods: {
      async submitCustomer({ data, eventName }) {
        try {
          await this.$validator.validateAll();
          if (this.formIsInvalid) {
            return;
          }
          let createdCustomer = null;
          this.loading = true;
          this.customer.customerCompany = this.customerCompany;
          if (!this.customer.password && this.container.hideCreatePassword) {
            this.customer.password = Math.random()
          }
          if (this.customer._id) {
            this.customer.CustomerPhoneNumbers.forEach((phone) => {
              delete phone._id;
            });
            await this.$api.customers.updateCustomer(this.customer);
            await this.$api.customers.createCustomerPhones({
              _id: this.customer._id,
              phones: this.customer.CustomerPhoneNumbers,
            });
          } else {
            const accountManager = Cookie.getJSON('account_manager');
            if (accountManager) {
              this.customer.userId = accountManager;
            }
            this.customer.distributorId = this.storeInformation.distributorId;
            this.customer.stores = [this.storeId];
            this.customer.source = 'Web';
            createdCustomer = (await this.$api.customers.createCustomer(this.customer)).data.model;

            this.$api.customers.addCustomerCreateAccountDevice(createdCustomer._id);

            this.onDataLayerSignUp(createdCustomer);
          }
          this.notificateSuccess('Customer saved');
          const submitCustomer = createdCustomer || this.customer;
          if (this.emitSubmit) {
            this.$emit('submitCustomer', submitCustomer);
            return;
          }
          this.cancel(submitCustomer);
        } catch (err) {
          if (
            (err.data && err.data.$$notification && err.data.$$notification.error.match(/email/i)) ||
            err.message.match('422')
          ) {
            this.notificateError(err.response?.data?.$$notification?.error || 'Customer with this email was already created');
          }
          this.loading = false;
        }
      },
      validateState(ref) {
        try {
          if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
            return !this.errors.has(ref);
          }
        } catch (e) {}
        return null;
      },
      switchVisibility() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      },
      generatePassword() {
        this.$set(
          this.customer,
          'password',
          generator.generate({
            length: 32,
            numbers: true,
            uppercase: true,
          })
        );
      },
      async cancel(customer) {
        let cartOrders = Cookie.get('cartOrders') || undefined;
        cartOrders = cartOrders ? JSON.parse(cartOrders) : [];
        if (cartOrders.length && customer) {
          await this.$store.dispatch('cart/clear', {});
          Cookie.remove('cartOrders');
          try {
            await Promise.all(
              cartOrders.map((order) => {
                const orderId = order._id;
                return this.$axios.put(`/orders/${orderId}/mergecart`, {
                  id: orderId,
                  cartType: order.type,
                  customerId: customer._id,
                  action: 1,
                });
              })
            );
          } catch (e) {}
          await this.$store.dispatch('cart/loadOrders', {
            orders: cartOrders.map((i) => i._id),
            customerId: customer._id,
            storeInformation: this.storeInformation,
            forceLoad: true,
          });
        }
        await this.logIn(Object.assign(this.customer, { storeUrl: this.storeInformation.storeUrl }));
        if (this.type === 'Edit') {
          this.$router.push('/account');
        } else {
          this.$router.push(`/createaccountconfirmation`);
        }
        this.loading = false;
      },
    },
  };
